import React from 'react'
import { Link, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from "@emotion/styled"

import Layout from '../components/layout'
import Topsection from '../components/topsection'
import Grid from '../components/grid'
import Seo from '../components/seo'

const Main = styled.main`
padding-bottom: 10vh;
`

const Tags = ({pageContext, data}) => {
    const { edges } = data.allPrismicFullProject
    const { tag } = pageContext
    
    return (
      <Layout>
        <Seo title={tag} />
      <Topsection>
        <h3>Section</h3>
        <h1>{tag}</h1>
      </Topsection>
      <Main>
        <Grid>
        <ul>
        {edges.map(({ node }) => {
          return (
            <li key={node.url}>
              <Link to={node.url}>
              <GatsbyImage image={node.data.banner_image.gatsbyImageData} alt="image" objectFit="cover" />
              <h2>{node.data.project_title.text}</h2>
              <h3>{node.data.subtitle.text}</h3>
              </Link>
            </li>
          )
        })}
        </ul>
        </Grid>
      </Main>
    </Layout>
    )
}

export default Tags

export const pageQuery = graphql`
query ($tag: String) {
    allPrismicFullProject(
          filter: { tags: { in: [$tag] } }
          sort: {fields: data___date, order: DESC}
    ) {
        edges {
            node {
              tags
              url
              data {
                date
                quick_post_
                project_title {
                  text
                }
                subtitle {
                  text
                }
                banner_image {
                  gatsbyImageData
                }
              }
            }
          }
        }
  }
`