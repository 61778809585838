import React from "react"
import styled from "@emotion/styled"

const Gridwrap = styled.section`
width: 80vw;
margin: 0px auto;
padding-top: 10px;
@media screen and (max-width: 767px) {
    width: auto;
    margin: 0px 20px;
}
ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media screen and (max-width: 767px) {
        width: 100%;
        grid-template-columns: 1fr 1fr;
    }
    li {
        padding-bottom: 10px;
        margin-bottom: 9px;
        border-bottom: solid 1px rgba(0,0,0,0.2);
        text-align: center;
        &:first-of-type a {
            border-left: none;
        }
        &:nth-of-type(3n + 4) a {
            border-left: none;
        }
        a {
        border-left: solid 1px rgba(0,0,0,0.4);
        border-bottom: none;
        padding: 10px 10px 20px 10px;
        display: block;
        opacity: 0.7;
        transition: all 0.3s ease-out;
        overflow: hidden;
        @media screen and (max-width: 767px) {
            width: auto;
            border: none;
            min-height: 135px;
            padding-bottom: 10px;
            &:nth-of-type(2n) {
                border-left: solid 1px rgba(0,0,0,0.4);
            }
        }
        &:hover {
            opacity: 1;
        }
        img {
            height: 220px;
            max-width: 100%;
            margin-bottom: 24px;
        }
        h2 {
            font-family: "Freight Big Black Regular";
            line-height: 1em;
            font-size: 1.5rem;
            padding-bottom: 2px;
        }
        h3 {
            font-family: "FreightText Light Italic";
            font-size: 16px;
            line-height: 1.2em;
        }
        }
    }
}
`

const Grid = ({children}) => {
    return (
        <Gridwrap>
            {children}
        </Gridwrap>
    )
}

export default Grid